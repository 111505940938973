import { render, staticRenderFns } from "./App.vue?vue&type=template&id=3ec8f6e1"
import script from "./App.vue?vue&type=script&lang=js"
export * from "./App.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.13_css-loader@6.11.0_webpack@5.97.1__lodash@4.17.21__nkjjb5puplgtn2g67cuu4wxlfy/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports