import Vue from "vue";
import Router from "vue-router";
import store from "./store";
import { HTTP } from "@/service/axios.js";

const titlePlatform = " | MANA Platform";
Vue.use(Router);

const ifNotVertifyMail = (to, from, next) => {
  if (!store.state.token || typeof store.state.token === "undefined") {
    next("/signin");
  } else if (
    typeof store.state.user.activeEmail === "undefined" ||
    store.state.user.activeEmail === 0
  ) {
    next();
  } else {
    next("/");
  }
};

const ifDeleteAuthenticated = async (to, from, next) => {
  await checkRefreshToken();

  if (!store.state.token || typeof store.state.token === "undefined") {
    let path = `/signin`;
    if (
      typeof to.query.os !== "undefined" &&
      to.query.os &&
      to.query.os == "app"
    ) {
      path += `?os=${to.query.os}&redirect=${to.fullPath}`;
    }
    next(path);
  } else if (store.state.user && store.state.user.deleteAccount) {
    let path = `/account/deleted`;
    if (
      typeof to.query.os !== "undefined" &&
      to.query.os &&
      to.query.os == "app"
    ) {
      path += `?os=${to.query.os}`;
    }
    next(path);
  } else {
    next();
  }
};

const ifDeletedAccount = async (to, from, next) => {
  await checkRefreshToken();

  if (!store.state.token || typeof store.state.token === "undefined") {
    next("/signin");
  } else {
    next();
  }
};

const ifAuthenticated = async (to, from, next) => {
  await checkRefreshToken();

  if (!store.state.token || typeof store.state.token === "undefined") {
    next("/signin");
  } else if (store.state.user && store.state.user.deleteAccount) {
    next("/account/deleted");
  } else {
    next();
  }
};

const ifAuthenticatedInSignin = (to, from, next) => {
  if (
    !store.state.user ||
    !store.state.token ||
    typeof store.state.token === "undefined"
  ) {
    next("/signin");
  } else if (store.state.user && store.state.user.deleteAccount) {
    next("/account/deleted");
  } else if (store.state.workspaces.length > 0) {
    next(
      "/project/" +
        store.state.workspaces[store.state.workspaces.length - 1]._id
    );
  } else {
    next("/signin");
  }
};

const ifAuthenticatedPremium = async (to, from, next) => {
  await checkRefreshToken();

  if (!store.state.token || typeof store.state.token === "undefined") {
    next("/signin");
  } else if (store.state.user && store.state.user.deleteAccount) {
    next("/account/deleted");
  } else if (store.state.user.level === 0) {
    next("/");
  } else {
    next();
  }
};

const checkRefreshToken = async () => {
  try {
    if (
      typeof store.state.user !== "undefined" &&
      store.state.user &&
      typeof store.state.user.token !== "undefined" &&
      Date.now() > store.state.user.token.exp - 1000 * 60 * 60 * 23.5
    ) {
      HTTP.defaults.headers.common.Authorization = `Bearer ${store.state.user.refreshToken.data}`;
      let res = await HTTP.post(`/auth/refresh`);

      if (res.data.success) {
        store.commit("SET_TOKEN", res.data.obj.token.data);
        store.commit("SET_USER_KEY", res.data.obj);
      }
    }
    return true;
  } catch (error) {
    console.error("checkRefreshToken error", error);
    return true;
  }
};

export default new Router({
  mode: "history",
  routes: [
    { path: "*", component: () => import("@/views/ErrorScreen") },
    {
      path: "/",
      // redirect: '/project',
      beforeEnter: ifAuthenticatedInSignin,
    },
    {
      path: "/signin",
      name: "signin",
      component: () => import("@/views/Signin"),
      hidden: true,
      meta: {
        title: "Sign In" + titlePlatform,
        metaTags: [
          {
            name: "description",
            content: "Work from home ทำงานออนไลน์ รับมือโควิด",
          },
          {
            property: "og:description",
            content:
              "ระบบทำงานออนไลน์โดยคนไทยเพื่อรับสถานการณ์โควิด ทำงานจากที่บ้านได้",
          },
        ],
      },
      // beforeEnter: ifAuthenticatedInSignin
    },
    {
      path: "/signout",
      name: "signout",
      component: () => import("@/views/Signout"),
      hidden: true,
    },
    {
      path: "/join/:workSpaceId?/:projectId?/:guestStatus?",
      name: "join",
      component: () => import("@/views/JoinworkSpace"),
      hidden: true,
      meta: {
        title: "Join | MANA Project Management",
        metaTags: [
          {
            name: "description",
            content: "Work from home ทำงานออนไลน์ รับมือโควิด",
          },
          {
            property: "og:description",
            content:
              "ระบบทำงานออนไลน์โดยคนไทยเพื่อรับสถานการณ์โควิด ทำงานจากที่บ้านได้",
          },
        ],
      },
    },
    // Invite Guest
    // {
    //   path: "/guest/:workSpaceId?/:projectId?",
    //   name: "guest",
    //   component: () => import("@/views/GuestworkSpace"),
    //   hidden: true,
    //   meta: {
    //     title: "Guest | MANA Project Management",
    //     metaTags: [
    //       {
    //         name: "description",
    //         content: "Work from home ทำงานออนไลน์ รับมือโควิด",
    //       },
    //       {
    //         property: "og:description",
    //         content:
    //           "ระบบทำงานออนไลน์โดยคนไทยเพื่อรับสถานการณ์โควิด ทำงานจากที่บ้านได้",
    //       },
    //     ],
    //   },
    // },
    {
      path: "/signup",
      name: "signup",
      props: (route) => ({
        workspace: route.query,
      }),
      component: () => import("@/views/Signup"),
      hidden: true,
      meta: {
        title: "Sign Up | MANA Project Management",
        metaTags: [
          {
            name: "description",
            content: "Work from home ทำงานออนไลน์ รับมือโควิด",
          },
          {
            property: "og:description",
            content:
              "ระบบทำงานออนไลน์โดยคนไทยเพื่อรับสถานการณ์โควิด ทำงานจากที่บ้านได้",
          },
        ],
      },
    },
    {
      path: "/changelog",
      name: "changelog",
      component: () => import("@/views/ChangeLog"),
      hidden: true,
      meta: {
        title: "Changelog | MANA Project Management",
        metaTags: [
          {
            name: "description",
            content: "Work from home ทำงานออนไลน์ รับมือโควิด",
          },
          {
            property: "og:description",
            content:
              "ระบบทำงานออนไลน์โดยคนไทยเพื่อรับสถานการณ์โควิด ทำงานจากที่บ้านได้",
          },
        ],
      },
    },
    {
      path: "/forgotpassword",
      name: "forgotpassword",
      component: () => import("@/views/ForgotPassword"),
      hidden: true,
      meta: {
        title: "Forgot Password" + titlePlatform,
        metaTags: [
          {
            name: "description",
            content: "Work from home ทำงานออนไลน์ รับมือโควิด",
          },
          {
            property: "og:description",
            content:
              "ระบบทำงานออนไลน์โดยคนไทยเพื่อรับสถานการณ์โควิด ทำงานจากที่บ้านได้",
          },
        ],
      },
    },
    {
      path: "/repassword/:secret",
      name: "repassword",
      component: () => import("@/views/RePassword"),
      hidden: true,
      meta: {
        title: "Reset your Password" + titlePlatform,
        metaTags: [
          {
            name: "description",
            content: "Work from home ทำงานออนไลน์ รับมือโควิด",
          },
          {
            property: "og:description",
            content:
              "ระบบทำงานออนไลน์โดยคนไทยเพื่อรับสถานการณ์โควิด ทำงานจากที่บ้านได้",
          },
        ],
      },
    },
    {
      path: "/new-workspace",
      name: "newworkspace",
      component: () => import("@/views/NewWorkSpace"),
      hidden: true,
      meta: {
        title: "Work space" + titlePlatform,
        breadcrumb: [{ title: "Work space", path: "/workspace" }],
      },
    },
    {
      path: "/notification/:type/:workSpaceId?",
      name: "notification",
      component: () => import("@/views/Notification"),
      hidden: false,
      meta: {
        menu: "การแจ้งเตือน",
        title: "Notification" + titlePlatform,
        icon: "notification",
        breadcrumb: [{ title: "Notification", path: "/notification" }],
        activeMenu: "notification",
      },
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/dashboard/:workSpaceId?",
      name: "dashboard",
      component: () => import("@/views/Dashboard"),
      hidden: false,
      meta: {
        menu: "ภาพรวม",
        title: "Dashboard" + titlePlatform,
        icon: "dashboard",
        breadcrumb: [{ title: "Dashboard", path: "/dashboard" }],
        activeMenu: "dashboard",
      },
      beforeEnter: ifAuthenticatedPremium,
    },
    {
      path: "/dashboardproject/:workSpaceId?",
      name: "dashboard",
      component: () => import("@/views/DashboardProject"),
      hidden: true,
      meta: {
        menu: "ภาพรวม",
        title: "Dashboard" + titlePlatform,
        icon: "dashboard",
        breadcrumb: [{ title: "Dashboard Project", path: "/dashboardproject" }],
        activeMenu: "dashboard",
      },
      beforeEnter: ifAuthenticatedPremium,
    },
    {
      path: "/dashboardproject/:workSpaceId?/:projectId",
      name: "dashboard",
      component: () => import("@/views/DashboardProjectDetail"),
      hidden: true,
      meta: {
        menu: "ภาพรวม",
        title: "Dashboard" + titlePlatform,
        icon: "dashboard",
        breadcrumb: [{ title: "Dashboard Project", path: "/dashboardproject" }],
        activeMenu: "dashboard",
      },
      beforeEnter: ifAuthenticatedPremium,
    },
    {
      path: "/dashboardmember/:workSpaceId?",
      name: "dashboard",
      component: () => import("@/views/DashboardMember"),
      hidden: true,
      meta: {
        menu: "ภาพรวม",
        title: "Dashboard" + titlePlatform,
        icon: "dashboard",
        breadcrumb: [{ title: "Dashboard Member", path: "/dashboardmenber" }],
        activeMenu: "dashboard",
      },
      beforeEnter: ifAuthenticatedPremium,
    },
    {
      path: "/dashboardhappy/:workSpaceId?",
      name: "dashboard",
      component: () => import("@/views/DashboardHappy"),
      hidden: true,
      meta: {
        menu: "ภาพรวม",
        title: "Dashboard" + titlePlatform,
        icon: "dashboard",
        breadcrumb: [{ title: "Dashboard Happy", path: "/dashboardhappy" }],
        activeMenu: "dashboard",
      },
      beforeEnter: ifAuthenticatedPremium,
    },
    {
      path: "/dashboardbudget/:workSpaceId?",
      name: "dashboard",
      component: () => import("@/views/Dashboardbudget"),
      hidden: true,
      meta: {
        menu: "ภาพรวม",
        title: "Dashboard" + titlePlatform,
        icon: "dashboard",
        breadcrumb: [{ title: "Dashboard Project", path: "/dashboardbudget" }],
        activeMenu: "dashboard",
      },
      beforeEnter: ifAuthenticatedPremium,
    },
    {
      path: "/project/:workSpaceId?",
      name: "project",
      component: () => import("@/views/Project"),
      hidden: false,
      meta: {
        menu: "โปรเจค",
        title: "Project Management" + titlePlatform,
        icon: "form",
        breadcrumb: [{ title: "Project", path: "/project" }],
        activeMenu: "project",
      },
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/profile/:workSpaceId?",
      name: "profile",
      component: () => import("@/views/Profile"),
      hidden: true,
      meta: {
        menu: "ข้อมูลส่วนตัว",
        title: "Profile" + titlePlatform,
        breadcrumb: [{ title: "ข้อมูลส่วนตัว", path: "/profile" }],
      },
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/password/:workSpaceId?",
      name: "password",
      component: () => import("@/views/Password"),
      hidden: true,
      meta: {
        menu: "เปลี่ยนรหัสผ่าน",
        title: "Password" + titlePlatform,
        breadcrumb: [{ title: "เปลี่ยนรหัสผ่าน", path: "/password" }],
      },
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/payment/:workSpaceId?",
      name: "payment",
      component: () => import("@/views/Payment"),
      hidden: true,
      meta: {
        menu: "ข้อมูลส่วนตัว",
        title: "Payment" + titlePlatform,
        breadcrumb: [{ title: "ข้อมูลส่วนตัว", path: "/Payment" }],
      },
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/members/:workSpaceId?",
      name: "members",
      component: () => import("@/views/Members"),
      hidden: false,
      meta: {
        menu: "สมาชิก",
        title: "Workspace's Members" + titlePlatform,
        icon: "user",
        breadcrumb: [{ title: "Members", path: "/members" }],
        activeMenu: "members",
      },
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/fileworkspace/:workSpaceId?",
      name: "fileworkspace",
      component: () => import("@/views/FileWorkspace"),
      hidden: false,
      meta: {
        menu: "ไฟล์",
        title: "Workspace's Filelist" + titlePlatform,
        icon: "tab",
        breadcrumb: [{ title: "File", path: "/file-wordspace" }],
        activeMenu: "file",
      },
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/fileprojectworkspace/:workSpaceId?",
      name: "fileworkspace",
      component: () => import("@/views/FileProjectWorkspace"),
      props: (route) => ({
        routeParams: route.query,
      }),
      hidden: true,
      meta: {
        menu: "ไฟล์",
        title: "Workspace's Filelist" + titlePlatform,
        icon: "tab",
        breadcrumb: [{ title: "File", path: "/file-wordspace" }],
        activeMenu: "file",
      },
      beforeEnter: ifAuthenticated,
    },

    {
      path: "/fileprojectworkspace/:workSpaceId?/:projectId",
      name: "fileworkspace",
      component: () => import("@/components/workspace/FileTaskcolumnWorkspace"),
      props: (route) => ({
        routeParams: route.query,
      }),
      hidden: true,
      meta: {
        menu: "ไฟล์",
        title: "Workspace's Filelist" + titlePlatform,
        icon: "tab",
        breadcrumb: [{ title: "File", path: "/file-wordspace" }],
        activeMenu: "file",
      },
      beforeEnter: ifAuthenticated,
    },

    {
      path: "/fileprojectworkspace/:workSpaceId?/:projectId/:columnId",
      name: "fileworkspace",
      component: () => import("@/components/workspace/FileTaskWorkspace"),
      props: (route) => ({
        routeParams: route.query,
      }),
      hidden: true,
      meta: {
        menu: "ไฟล์",
        title: "Workspace's Filelist" + titlePlatform,
        icon: "tab",
        breadcrumb: [{ title: "File", path: "/file-wordspace" }],
        activeMenu: "file",
      },
      beforeEnter: ifAuthenticated,
    },

    {
      path: "/fileprojectworkspace/:workSpaceId?/:projectId/:columnId/:taskId",
      name: "fileworkspace",
      component: () => import("@/components/workspace/FileWorkspace"),
      props: (route) => ({
        routeParams: route.query,
      }),
      hidden: true,
      meta: {
        menu: "ไฟล์",
        title: "Workspace's Filelist" + titlePlatform,
        icon: "tab",
        breadcrumb: [{ title: "File", path: "/file-wordspace" }],
        activeMenu: "file",
      },
      beforeEnter: ifAuthenticated,
    },

    // {
    //   path: "/goal/old/:workSpaceId?",
    //   name: "goal/old",
    //   component: () => import("@/views/Goal"),
    //   hidden: false,
    //   meta: {
    //     menu: "เป้าหมาย",
    //     title: "Workspace's Goal" + titlePlatform,
    //     icon: "bullseye",
    //     breadcrumb: [{ title: "Goal", path: "/goal" }],
    //     activeMenu: "goal",
    //   },
    //   beforeEnter: ifAuthenticatedPremium,
    // },
    {
      path: "/goal/type",
      name: "goal/type",
      component: () => import("@/views/GoalType"),
      hidden: false,
      meta: {
        menu: "เป้าหมาย",
        title: "Workspace's Goal" + titlePlatform,
        icon: "bullseye",
        breadcrumb: [],
        activeMenu: "goal",
        newSideBar: true,
      },
      beforeEnter: ifAuthenticatedPremium,
    },
    {
      path: "/goal/:goalType",
      name: "goal",
      component: () => import("@/views/GoalNew"),
      hidden: true,
      meta: {
        title: "Goals | MANA Project Management",
        activeMenu: "goal",
        newSideBar: true,
      },
      beforeEnter: ifAuthenticatedPremium,
    },
    {
      path: "/objective/list/:goalType/:goalId/:organizationId",
      name: "objectiveList",
      component: () => import("@/views/ObjectiveList"),
      hidden: true,
      meta: {
        title: "Goals | MANA Project Management",
        activeMenu: "goal",
        newSideBar: true,
      },
      beforeEnter: ifAuthenticatedPremium,
    },
    {
      path: "/objective/detail/:goalType/:goalId/:organizationId",
      name: "objectiveDetail",
      component: () => import("@/views/ObjectiveDetail"),
      hidden: true,
      meta: {
        title: "Goals | MANA Project Management",
        activeMenu: "goal",
        newSideBar: true,
      },
      beforeEnter: ifAuthenticatedPremium,
    },

    {
      path: "/key/result/:goalType/:goalId/:organizationId",
      name: "keyResult",
      component: () => import("@/views/KeyResult"),
      hidden: true,
      meta: {
        title: "Goals | MANA Project Management",
        activeMenu: "goal",
        newSideBar: true,
      },
      beforeEnter: ifAuthenticatedPremium,
    },

    {
      path: "/file/:workSpaceId?/:projectId",
      name: "filelist",
      component: () => import("@/views/FileProject"),
      hidden: true,
      meta: {
        title: "File list" + titlePlatform,
        breadcrumb: [
          { title: "Project", path: "/project" },
          { title: "File", path: "/file" },
        ],
        activeMenu: "project",
      },
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/gantt/:workSpaceId?/:projectId",
      name: "gantt",
      component: () => import("@/views/Gantt"),
      hidden: true,
      meta: {
        title: "Timeline" + titlePlatform,
        breadcrumb: [
          { title: "Project", path: "/project" },
          { title: "Timeline", path: "/gantt" },
        ],
        activeMenu: "project",
      },
      beforeEnter: ifAuthenticatedPremium,
    },
    {
      path: "/calendar/:workSpaceId?/:projectId",
      name: "calendar",
      component: () => import("@/views/Calendar"),
      hidden: true,
      meta: {
        title: "Calendar" + titlePlatform,
        breadcrumb: [
          { title: "Project", path: "/project" },
          { title: "Calendar", path: "/calendar" },
        ],
        activeMenu: "project",
      },
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/task/:workSpaceId?/:projectId/:taskId?",
      name: "task",
      component: () => import("@/views/Task"),
      hidden: true,
      meta: {
        title: "Task" + titlePlatform,
        breadcrumb: [
          { title: "Project", path: "/project" },
          { title: "Task", path: "/Task" },
        ],
        activeMenu: "project",
      },
      props: (route) => ({
        routeParams: route.query,
      }),
      beforeEnter: ifAuthenticated,
    },

    {
      path: "/new/task/:workSpaceId?/:projectId",
      name: "task",
      component: () => import("@/views/Task"),
      hidden: true,
      meta: {
        title: "Task" + titlePlatform,
        breadcrumb: [
          { title: "Project", path: "/project" },
          { title: "Task", path: "/Task" },
        ],
        activeMenu: "/project",
      },
      props: (route) => ({
        routeParams: route.query,
      }),
      beforeEnter: ifAuthenticated,
    },

    {
      path: "/list/:workSpaceId?/:projectId",
      name: "list",
      component: () => import("@/views/NewList"),
      hidden: true,
      meta: {
        title: "List" + titlePlatform,
        breadcrumb: [
          { title: "Project", path: "/project" },
          { title: "List", path: "/List" },
        ],
        activeMenu: "project",
      },
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/role/:workSpaceId?/:projectId",
      name: "roleUser",
      component: () => import("@/views/RoleUser"),
      hidden: true,
      meta: {
        title: "User Role" + titlePlatform,
        breadcrumb: [
          { title: "Project", path: "/project" },
          { title: "role", path: "/role" },
        ],
        activeMenu: "/project",
      },
      beforeEnter: ifAuthenticatedPremium,
    },
    {
      path: "/mention",
      name: "mention",
      component: () => import("@/views/Mention"),
      props: (route) => ({
        workspace: route.query,
      }),
      hidden: true,
      meta: {
        title: "Mention | MANA Project Management",
        metaTags: [
          {
            name: "description",
            content: "Work from home ทำงานออนไลน์ รับมือโควิด",
          },
          {
            property: "og:description",
            content:
              "ระบบทำงานออนไลน์โดยคนไทยเพื่อรับสถานการณ์โควิด ทำงานจากที่บ้านได้",
          },
        ],
      },
    },
    {
      path: "/add-more-data",
      name: "signupmore",
      component: () => import("@/views/AddMoreSignup"),
      props: (route) => ({
        workspace: route.query,
      }),
      hidden: true,
      meta: {
        title: "Add more data | MANA Project Management",
        metaTags: [
          {
            name: "description",
            content: "Work from home ทำงานออนไลน์ รับมือโควิด",
          },
          {
            property: "og:description",
            content:
              "ระบบทำงานออนไลน์โดยคนไทยเพื่อรับสถานการณ์โควิด ทำงานจากที่บ้านได้",
          },
        ],
      },
    },
    {
      path: "/add-marketing",
      name: "addDataMarketing",
      component: () => import("@/views/AddDataMarketing"),
      props: (route) => ({
        workspace: route.query,
      }),
      hidden: true,
      meta: {
        title: "Add data marketing | MANA Project Management",
        metaTags: [
          {
            name: "description",
            content: "Work from home ทำงานออนไลน์ รับมือโควิด",
          },
          {
            property: "og:description",
            content:
              "ระบบทำงานออนไลน์โดยคนไทยเพื่อรับสถานการณ์โควิด ทำงานจากที่บ้านได้",
          },
        ],
      },
    },
    // {
    //   path: "/goal/old/list/:workSpaceId?",
    //   name: "goalList",
    //   component: () => import("@/views/GoalList"),
    //   hidden: true,
    //   meta: {
    //     title: "Goals | MANA Project Management",
    //     activeMenu: "goal",
    //   },
    //   beforeEnter: ifAuthenticatedPremium,
    // },
    // {
    //   path: "/goal/old/manage/:workSpaceId?",
    //   name: "goalManage",
    //   component: () => import("@/views/GoalManage"),
    //   props: (route) => ({
    //     routeParams: route.query,
    //   }),
    //   hidden: true,
    //   meta: {
    //     title: "Goals | MANA Project Management",
    //     activeMenu: "goal",
    //   },
    //   beforeEnter: ifAuthenticatedPremium,
    // },
    {
      path: "/notiserviceworker",
      name: "notiServiceworker",
      component: () => import("@/views/NotiServiceworker"),
      props: (route) => ({
        routeParams: route.query,
      }),
      hidden: true,
      meta: {
        title: "Test | MANA Project Management",
      },
    },
    {
      path: "/testSubscribe",
      name: "testSubscribe",
      component: () => import("@/views/TestSubscribe"),
      props: (route) => ({
        routeParams: route.query,
      }),
      hidden: true,
      meta: {
        title: "Test | MANA Project Management",
      },
    },
    {
      path: "/signup/success",
      name: "signupSuccess",
      component: () => import("@/views/SignupSuccess"),
      hidden: true,
      meta: {
        title: "Signup success | MANA Project Management",
      },
    },

    {
      path: "/verify/mail",
      name: "verifyMail",
      component: () => import("@/views/VerifyMail"),
      hidden: true,
      meta: {
        title: "Verify Mail | MANA Project Management",
      },
      beforeEnter: ifNotVertifyMail,
    },
    {
      path: "/confirm/mail",
      name: "confirmMail",
      component: () => import("@/views/ConfirmMail"),
      hidden: true,
      meta: {
        title: "Verify Mail | MANA Project Management",
      },
    },
    {
      path: "/success/payment/credit/:id/:code",
      name: "successPaymentCredit",
      component: () => import("@/views/SuccessPaymentCredit"),
      hidden: true,
      meta: {
        title: "Success Payment | MANA Project Management",
      },
    },
    {
      path: "/delete/account/:workSpaceId?",
      name: "deleteAccount",
      component: () => import("@/views/DeleteAccount"),
      hidden: true,
      beforeEnter: ifDeleteAuthenticated,
    },
    {
      path: "/account/deleted",
      name: "accountDeleted",
      component: () => import("@/views/AccountDeleted"),
      hidden: true,
      beforeEnter: ifDeletedAccount,
    },
    {
      path: "/pay/package",
      name: "payPackage",
      component: () => import("@/views/PayPackage"),
      hidden: true,
      meta: {
        template: "payment",
      },
      beforeEnter: ifDeletedAccount,
    },
    {
      path: "/success/pay-solution",
      name: "payPackage",
      component: () => import("@/views/SuccessPaysolution"),
      hidden: true,
      meta: {
        template: "payment",
      },
      beforeEnter: ifDeletedAccount,
    },
    {
      path: "/trashcan/:workSpaceId?",
      name: "trashcan",
      component: () => import("@/views/Trashcan"),
      hidden: true,
      beforeEnter: ifDeletedAccount,
    },
  ],
});
